<div class="page-padding">

    <div class="header">
        <app-title-deco></app-title-deco>
        <div class="title">¡Resuelve tus dudas! ;)</div>
    </div>

    <div class="row features">
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <figure><i class="fas fa-check-circle"></i></figure>
            <div class="title">Roaming en Europa</div>
            <div class="description">¿En qué países podré navegar? ¿Tiene algún límite?</div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <figure><i class="fas fa-check-circle"></i></figure>

            <div class="title">Cobertura</div>
            <div class="description"> ¿Qué cobertura usáis? ¿Tendré conexión en toda España?</div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <figure><i class="fas fa-check-circle"></i></figure>

            <div class="title"> Darse de alta</div>
            <div class="description"> ¿Puedo mantener mi número? ¿Puedo contratar un número nuevo?</div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <figure><i class="fas fa-check-circle"></i></figure>

            <div class="title"> App móvil</div>
            <div class="description"> ¿Dónde puedo descargar vuestra app? ¿Está para iPhone y para Android?</div>

        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <figure><i class="fas fa-check-circle"></i></figure>
            <div class="title"> Facturación y consumo</div>
            <div class="description"> ¿Cuándo y cuánto me cobraréis? ¿Dónde puedo ver mi factura?</div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <figure><i class="fas fa-check-circle"></i></figure>
            <div class="title"> Tarjeta SIM</div>
            <div class="description"> ¿Cómo recibiré mi tarjeta SIM? ¿Qué tengo que hacer cuando la reciba?</div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <figure><i class="fas fa-check-circle"></i></figure>
            <div class="title"> Portabilidad</div>
            <div class="description"> ¿Cuánto tarda la portabilidad? ¿Me quedaré sin línea en algún momento?</div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <figure><i class="fas fa-check-circle"></i></figure>
            <div class="title"> Fibra</div>
            <div class="description"> ¿Cómo es el proceso de alta? ¿Cuándo vendrán a instalarme la fibra?</div>
        </div>
    </div>

    <div class="centro-link">
        <a [routerLink]="">
            Centro de ayuda <i class="fas fa-arrow-right"></i>
        </a>
    </div>

</div>
