import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LandingComponent} from './landing/landing.component';
import {TarifasComponent} from './tarifas/tarifas.component';
import {FaqComponent} from './faq/faq.component';
import {ContactoComponent} from './contacto/contacto.component';
import {TarifasCompartidasComponent} from './tarifas-compartidas/tarifas-compartidas.component';

const routes: Routes = [
    {
        path: '',
        component: LandingComponent
    },
    {
        path: 'tarifas/:type', component: TarifasComponent
    },
    {
        path: 'tarifas-compartidas', component: TarifasCompartidasComponent
    },
    {
        path: 'faq', component: FaqComponent
    },
    {
        path: 'contacto', component: ContactoComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
