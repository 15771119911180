import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CoberturaComponent} from './cobertura/cobertura.component';

@Component({
    selector: 'app-landing-marketing',
    templateUrl: './marketing.component.html',
    styleUrls: ['./marketing.component.scss', './../lading-pages.scss']
})
export class MarketingComponent implements OnInit {

    constructor(
        private dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
    }

    // tslint:disable-next-line:typedef
    openCobertura() {
        this.dialog.open(CoberturaComponent, {autoFocus: false});
    }
}
