<section class="todas-tarifas">
    <div class="container-fluid page-padding">
        <h3>Descubre las mejores Tarifas de Móvil sin Permanencia</h3>

        <div class="tarifas-lista">
            <div class="row">
                <div class="col-3 price-item" *ngFor="let tarifa of tarifasToShow">
                    <app-price [tarifa]="tarifa" [features]="false"></app-price>
                </div>

            </div>
        </div>


    </div>

</section>


<section class="feature container-fluid">

    <div class="page-padding">

        <div class="row d-flex align-items-center" *ngIf="type === 'movil'">
            <div class="col col-more-padding">
                <p class="title">¿Por qué elegir una tarifa de Nextfibra para tu móvil?</p>

                <app-title-deco></app-title-deco>

                <h2>Disfruta de la mejor Red a Velocidad 4G+ y Sin Permanencia</h2>

                <p>Te ofrecemos la mejor red de Internet Móvil de España con cobertura 4G+ de Vodafone / Movistar para
                    conectarte a Internet y hablar estés donde estés con los precios más competitivos del mercado.</p>
                <p>Nuestras tarifas móviles se adaptan a tus necesidades, con llamadas ilimitadas y gigas acumulativos
                    siempre podrás estar conectado/a.</p>
                <p>Te ofrecemos las mejores tarifas, todo ello con un excelente servicio de atención al cliente y con la
                    garantía y confianza de Nextfibra. Ponte en contacto con nosotros en el 954495990, por el formulario
                    o en nuestras oficinas.</p>


            </div>

            <div class="col col-more-padding">
                <img src="/assets/img/marketing/tarifas/feature.webp"/>

            </div>
        </div>


        <div class="row d-flex align-items-center" *ngIf="type === 'fibra'">
            <div class="col col-more-padding">
                <p class="title">¿Por qué elegir nuestra fibra?</p>

                <app-title-deco></app-title-deco>

                <h2>Disfruta de la mejor conexión en fibra óptica simétrica y Sin Permanencia</h2>

                <p>Por cercanía, porque no tenemos permanencia, porque sabemos escuchar y porque damos TODO para que no te preocupes por NADA, Nextfibra es tu operador de fibra local.</p>
                <p>Te ofrecemos las mejores tarifas, con un excelente servicio de atención al cliente y con la garantía y confianza de Nextfibra. Ponte en contacto con nosotros en el  954495990, por el formulario o en nuestras oficinas.</p>

            </div>

            <div class="col col-more-padding">
                <img src="/assets/img/marketing/tarifas/feature.webp"/>

            </div>
        </div>

    </div>

</section>


