<section class="todas-tarifas">
    <div class="container-fluid page-padding">
        <h3>Descubre las mejores Tarifas Compartidas y sin Permanencia</h3>

        <div class="tarifas-lista">
            <div class="row">
                <div class="col-3 price-item" *ngFor="let tarifa of tarifasToShow">
                    <app-price-compartido [tarifa]="tarifa" [features]="false"></app-price-compartido>
                </div>

            </div>
        </div>


    </div>

</section>




