import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Producto} from '../library/models/Producto';
import {ProductService} from '../library/product.service';
import {AppSettingsService} from '../library/appSettings.service';
import {BackdropService} from '../library/backdrop.service';

@Component({
    selector: 'app-tarifas',
    templateUrl: './tarifas.component.html',
    styleUrls: ['./tarifas.component.scss']
})
export class TarifasComponent implements OnInit {

    type: 'fibra' | 'movil' | 'movil+fibra';
    private sub: any;

    tarifasToShow: Array<Producto> = [];

    constructor(private route: ActivatedRoute,
                private productService: ProductService,
                private backdropService: BackdropService) {
        this.sub = this.route.params.subscribe(params => {
            this.type = params.type;

            switch (this.type) {
                case 'movil':
                    this.tarifasToShow = this.productService.tarifas.filter(x => x.isMovil && !x.isFibra);
                    break;
                case 'fibra':
                    this.tarifasToShow = this.productService.tarifas.filter(x => !x.isMovil && x.isFibra);
                    break;
                case 'movil+fibra':
                    this.tarifasToShow = this.productService.tarifas.filter(x => x.isMovil && x.isFibra);
                    break;
            }
        });


    }


    ngOnInit(): void {
        this.backdropService.closeBackdrop();
    }

}
