import {Injectable} from '@angular/core';
import {Producto} from './models/Producto';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {async} from 'rxjs/internal/scheduler/async';
import {ProductoCompartido} from './models/ProductoCompartido';

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    tarifas: Array<Producto> = [];
    tarifasCompartidas: Array<ProductoCompartido> = [];

    private tarifasUrl = 'assets/tarifas.json';
    private tarifasCompartidasUrl = 'assets/tarifas-compartidas.json';

    constructor(private http: HttpClient) {
    }

    loadTarifas(): Promise<any> {

        const promise = this.http.get<Array<Producto>>(this.tarifasUrl)
            .toPromise()
            .then(data => {
                this.tarifas = data;
                return data;
            });

        return promise;
    }

    loadTarifasCompartidas(): Promise<any> {
        const promise = this.http.get<Array<ProductoCompartido>>(this.tarifasCompartidasUrl)
            .toPromise()
            .then(data => {
                this.tarifasCompartidas = data;
                return data;
            });

        return promise;
    }


}
