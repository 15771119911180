<div class="page-padding black">

    <div class="header">
        <app-title-deco></app-title-deco>
        <div class="title">Descarga nuestra app NextFibra</div>
        <div class="descripcion">Toma el control de tus servicios y gestiona tu cuenta desde nuestra aplicación.</div>
    </div>

    <div class="smaller">
        <div class="row">
            <div class="col-xs-12 col-md-6 mobile-image">
                <figure>
                    <img src="assets/img/marketing/app.png" alt=""/>
                </figure>
            </div>

            <div class="col-xs-12 col-md-6 features">
                <ul>
                    <li>
                        <svg width="12" height="12" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M9.39 3.312L5.072 8.711 1.68 5.884l.64-.768 2.608 2.173 3.681-4.601.781.624z"
                                  fill="#fff"></path>
                        </svg>
                        Comparte GB con otros usuarios de Fi.
                    </li>

                    <li>
                        <svg width="12" height="12" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M9.39 3.312L5.072 8.711 1.68 5.884l.64-.768 2.608 2.173 3.681-4.601.781.624z"
                                  fill="#fff"></path>
                        </svg>
                        Controla tu consumo del mes.
                    </li>

                    <li>
                        <svg width="12" height="12" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M9.39 3.312L5.072 8.711 1.68 5.884l.64-.768 2.608 2.173 3.681-4.601.781.624z"
                                  fill="#fff"></path>
                        </svg>
                        Consulta tus facturas.
                    </li>

                    <li>
                        <svg width="12" height="12" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M9.39 3.312L5.072 8.711 1.68 5.884l.64-.768 2.608 2.173 3.681-4.601.781.624z"
                                  fill="#fff"></path>
                        </svg>
                        Activa y desactiva el Roaming en cualquier momento.
                    </li>

                    <li>
                        <svg width="12" height="12" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M9.39 3.312L5.072 8.711 1.68 5.884l.64-.768 2.608 2.173 3.681-4.601.781.624z"
                                  fill="#fff"></path>
                        </svg>
                        Activa y desactiva tu Buzón de Voz.
                    </li>

                    <li>
                        <svg width="12" height="12" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M9.39 3.312L5.072 8.711 1.68 5.884l.64-.768 2.608 2.173 3.681-4.601.781.624z"
                                  fill="#fff"></path>
                        </svg>
                        Accede a promociones especiales y ahorra en tus facturas.
                    </li>

                </ul>

                <div class="download-app">
                    <a [href]="appSettings.appAndroid" target="_blank">
                        <img src="assets/img/designs/gplaystore.svg" alt=""/>
                    </a>
                    <a [href]="appSettings.appApple" target="_blank">
                        <img src="assets/img/designs/appstore.svg" alt=""/>
                    </a>
                </div>
            </div>
        </div>
    </div>

</div>
