<div class="container-fluid">
    <div class="page-padding">
        <div class="carousel">
            <!--  <ngb-carousel [interval]="6000" [pauseOnHover]="true">
                  <ng-template ngbSlide *ngFor="let slide of Slider; index as i">
                      <div class="carousel-caption d-none d-xl-block d-lg-block d-md-block">
                          <div class="caption">{{slide.caption}}</div>
                      </div>
                      <ng-container *ngIf="slide.link">
                          <a [href]="slide.link">
                              <div class="picsum-img-wrapper">
                                  <img [src]="slide.image" alt="My image {{i + 1}} description">
                              </div>
                          </a>
                      </ng-container>
                      <ng-container *ngIf="!slide.link">
                          <div class="picsum-img-wrapper">
                              <img [src]="slide.image" alt="My image {{i + 1}} description">
                          </div>
                      </ng-container>

                  </ng-template>
              </ngb-carousel>-->

            <video fullscreen="false" [muted]="'muted'" autoplay [loop]="'loop'">
                <source src="/assets/video/init.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>

        </div>
    </div>
</div>

