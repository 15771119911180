<div class="tarifa" [class.animated]="animation">

    <div class="titulo">{{tarifa.nombre}}</div>

    <div class="price">
        <span class="eur">{{tarifa.precioEUR}}</span>
        <span class="eur">’</span>
        <span class="eur">{{tarifa.precioCENT}}</span>
        <span class="mes">/ mes (iva inc.)</span>

    </div>

    <app-title-deco></app-title-deco>

    <div class="descripcion" *ngIf="features">
        {{tarifa.descripcion}}
    </div>

    <div class="caracteristicas" *ngIf="features">
        <div class="caracteristica" *ngFor="let car of tarifa.caracteristicas">{{car ? car : '&nbsp;'}}</div>
    </div>

    <div class="getIt">
        <button type="button" class="btn" [routerLink]="'/contacto'">¡Lo Quiero!</button>
    </div>


</div>
