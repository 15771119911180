<div class="cobertura-container">

    <button class="close" mat-dialog-close>
        <i class="fas fa-times"></i>
    </button>

    <div class="title">
        <h3>Consulta la cobertura en tu domicilio</h3>
        <p>Esta es la dirección donde instalaremos la fibra. Al final del proceso, podrás seleccionar la fecha y hora de
            la instalación.</p>
    </div>

    <div class="checker" *ngIf="state === 1">

        <div class="list">
            <ul class="list-unstyled">
                <li>
                    <svg width="12" height="12" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M9.39 3.312L5.072 8.711 1.68 5.884l.64-.768 2.608 2.173 3.681-4.601.781.624z"
                        ></path>
                    </svg>
                    Sin coste de línea fija, nada de cuotas extra.
                </li>
                <li>
                    <svg width="12" height="12" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M9.39 3.312L5.072 8.711 1.68 5.884l.64-.768 2.608 2.173 3.681-4.601.781.624z"
                        ></path>
                    </svg>
                    Velocidad simétrica según disponibilidad geográfica.
                </li>
                <li>
                    <svg width="12" height="12" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M9.39 3.312L5.072 8.711 1.68 5.884l.64-.768 2.608 2.173 3.681-4.601.781.624z"
                        ></path>
                    </svg>
                    Instalación y router gratis. Tú eliges fecha y hora.
                </li>
            </ul>
        </div>

        <div class="formulario">

            <div class="d-flex">
                <mat-form-field appearance="outline" class="direccion">
                    <mat-label>Dirección</mat-label>
                    <input matInput placeholder="Ej. Calle de Laraña" [(ngModel)]="direccion">
                </mat-form-field>

                <mat-form-field appearance="outline" class="numero">
                    <mat-label>Número</mat-label>
                    <input matInput placeholder="Nº" [(ngModel)]="numero">
                </mat-form-field>
            </div>
            <button mat-raised-button [disabled]="!direccion || !numero" (click)="checkCobertura()">
                Comprobar cobertura
            </button>
        </div>
    </div>

    <div class="loader" *ngIf="state === 2">
        <i class="fas fa-cog fa-spin"></i>
        <p>Buscando cobertura...</p>
    </div>


    <div class="result" *ngIf="state === 3">

        <div class="good"> ¡Enhorabuena!</div>
        <p>Parece que llega fibra a tu casa...</p>
        <p>!Llámanos para tener nuestra fibra dentro de poco!</p>

    </div>

</div>

