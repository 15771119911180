import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class AppSettingsService {
    tel = 954495990;
    appAndroid = 'https://play.google.com/store/apps/details?id=es.finetwork.vendor.mio&hl=es&gl=US';
    appApple = 'https://apps.apple.com/de/app/mio/id1159483966';
    social = {
        facebook: 'https://www.facebook.com/nextfibraumbrete',
        twitter: 'https://twitter.com/nextfibra',
        instagram: '',
        youtube: 'https://www.youtube.com/channel/UCdVuZ_iTmmoaaPsNwYI-zjQ'
    };
};
