import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbCarousel, NgbSlideEvent, NgbSlideEventSource} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-landing-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

    Slider: Array<Slider> = [
        {
            image: '/assets/img/marketing/landing-slider/4.webp',
            caption: 'PONTE MORADO: fibra 600Mb y Móvil 15GB por solo 39,99€',
            link: 'google.com'
        },
        {
            image: '/assets/img/marketing/landing-slider/2.webp',
            caption: 'PONTE MORADO: fibra 100Mb por solo 19,99€',
        },
        {
            image: '/assets/img/marketing/landing-slider/3.webp',
            caption: 'HOLA ESTO es una prueba',
        },
        {
            image: '/assets/img/marketing/landing-slider/1.webp',
            caption: 'HOLA ESTO es una prueba',
        }
    ];

    images = [1, 2, 3, 4].map((n) => `/assets/img/marketing/landing-slider/${n}.webp`);


    constructor() {
    }

    ngOnInit(): void {
    }


}

interface Slider {
    image: string;
    caption: string;
    link?: string;
}
