<div class="page-padding">

    <div class="header">
        <app-title-deco></app-title-deco>
        <div class="title">El top 3 de Nextfibra</div>
    </div>

    <div class="listado row">

        <div class="col-xs-12 col-md-4" *ngFor="let tarifa of tarifas">
            <app-price [tarifa]="tarifa" [animation]="true" [features]="true"></app-price>
        </div>

    </div>

</div>
