import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LibraryModule} from './library/library.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LandingModule} from './landing/landing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TarifasComponent} from './tarifas/tarifas.component';
import {FooterComponent} from './footer/footer.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialogModule} from '@angular/material/dialog';
import {FaqComponent} from './faq/faq.component';
import {ContactoComponent} from './contacto/contacto.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {HttpClientModule} from '@angular/common/http';
import {ProductService} from './library/product.service';
import { TarifasCompartidasComponent } from './tarifas-compartidas/tarifas-compartidas.component';

export function initTarifas(productService: ProductService) {
    return () => productService.loadTarifas();
}
export function initTarifasCompartidas(productService: ProductService) {
    return () => productService.loadTarifasCompartidas();
}

@NgModule({
    declarations: [
        AppComponent,
        TarifasComponent,
        FooterComponent,
        FaqComponent,
        ContactoComponent,
        TarifasCompartidasComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        LibraryModule,
        LandingModule,
        NgbModule,
        FormsModule,
        BrowserAnimationsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule
    ],

    providers: [
        {provide: APP_INITIALIZER, useFactory: initTarifas, deps: [ProductService], multi: true},
        {provide: APP_INITIALIZER, useFactory: initTarifasCompartidas, deps: [ProductService], multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
