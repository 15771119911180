<div class="container-fluid">
    <div class="row justify-content-between top-mini">
        <!--<div class="col d-none d-lg-block">
            Pack Ocio y Tranquilidad Familiar
        </div>-->
        <div class="col others">
            <div class="tel d-none d-lg-block">
                Llámanos gratis al <a [href]="'tel:' + appSettings.tel">{{appSettings.tel}} </a>
            </div>

            <div class="separator d-none d-lg-block"></div>

            <div class="help d-none d-lg-block">
                <a routerLink="/contacto"> ¿Necesitas ayuda? Te Llamamos GRATIS + </a>
            </div>
            <div class="media">
                <div class="icon"  *ngIf="appSettings.social.facebook">
                    <a [href]="appSettings.social.facebook" target="_blank"> <i class="fab fa-facebook-square"></i></a>
                </div>
                <div class="icon"  *ngIf="appSettings.social.twitter">
                    <a [href]="appSettings.social.twitter" target="_blank"> <i class="fab fa-twitter-square"></i></a>
                </div>
                <div class="icon"  *ngIf="appSettings.social.youtube">
                    <a [href]="appSettings.social.youtube" target="_blank"> <i class="fab fa-youtube-square"></i></a>
                </div>
                <div class="icon" *ngIf="appSettings.social.instagram">
                    <a [href]="appSettings.social.instagram" target="_blank"> <i class="fab fa-instagram-square"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>


<nav class="container-fluid d-inline-flex">
    <div class="logo" [routerLink]="'/'">
        <img src="../../../assets/img/logo.png" width="120"/>
    </div>

    <div class="drop-item d-none d-xl-block d-lg-block d-md-block" (mouseover)="backdropService.openBackdrop()"
         (mouseleave)="backdropService.closeBackdrop()">
        <div class="txt">Tarifas Móvil<i class="fas  fa-chevron-down"></i></div>
        <div class="drop-item-content container-fluid">
            <div class="row">
                <div class="col left">
                    <div class="title">¿Te faltan GB? A nosotros nos sobran</div>
                    <div class="subtitle">¡Máximo ahorro en móvil, sin permanencia! ¡Acumula y comparte tus GB!
                    </div>

                    <div class="row tarifas-short tarifas">
                        <div class="col-xs-12 col-md-6" *ngFor="let tm of tarifasMovil">
                            <div><a [routerLink]="'contacto'">{{tm.nombre}}</a></div>
                        </div>

                    </div>

                    <div class="other">
                        <a [routerLink]="'tarifas/movil'">Todas las Tarifas de Móvil <i
                                class="fas fa-angle-right"></i></a>
                    </div>
                </div>
                <div class="col image">

                </div>

            </div>
        </div>
    </div>

    <div class="drop-item d-none d-xl-block d-lg-block d-md-block" (mouseover)="backdropService.openBackdrop()"
         (mouseleave)="backdropService.closeBackdrop()">
        <div class="txt">Tarifas Fibra<i class="fas  fa-chevron-down"></i></div>
        <div class="drop-item-content container-fluid">
            <div class="row">
                <div class="col-8 left">
                    <div class="title">¿Te hemos tocado la fibra?</div>

                    <div class="row tarifas-long tarifas">
                        <div class="col" *ngFor="let tf of tarifasFibra">
                            <div class="link"><a [routerLink]="'contacto'">{{tf.nombre}}</a></div>
                            <div class="explain">
                                {{tf.descripcion}}
                            </div>
                        </div>

                    </div>

                    <div class="other">
                        <a [routerLink]="'tarifas/fibra'">Todas las Tarifas de Fibra <i
                                class="fas fa-angle-right"></i></a>
                    </div>
                </div>
                <div class="col image">

                </div>

            </div>
        </div>
    </div>

    <div class="drop-item d-none d-xl-block d-lg-block d-md-block" (mouseover)="backdropService.openBackdrop()"
         (mouseleave)="backdropService.closeBackdrop()" *ngIf="tarifasFibraYMovil.length > 0">
        <div class="txt">Tarifas Fibra + Móvil<i class="fas  fa-chevron-down"></i></div>
        <div class="drop-item-content container-fluid">
            <div class="row">
                <div class="col-8 left">
                    <div class="title">Descubre nuestros combos de Fibra y Móvil</div>
                    <div class="subtitle">Elige las líneas móviles que necesitas y la velocidad de tu fibra ¡Puedes
                        añadir más líneas móviles con descuento!
                    </div>

                    <div class="row tarifas-short tarifas">
                        <div class="col-xs-12 col-md-6" *ngFor="let tfm of tarifasFibraYMovil">
                            <div><a [routerLink]="'contacto'">{{tfm.nombre}}</a></div>
                        </div>
                    </div>

                    <div class="other">
                        <a [routerLink]="'tarifas/movil+fibra'">Todas las Tarifas de Fibra + Móvil <i
                                class="fas fa-angle-right"></i></a>
                    </div>
                </div>
                <div class="col image">

                </div>

            </div>
        </div>
    </div>

    <div class="drop-item d-none d-xl-block d-lg-block d-md-block" [routerLink]="'tarifas-compartidas'">
        <div class="txt">Tarifas Compartidas</div>
    </div>

    <div class="drop-item d-none d-xl-block d-lg-block d-md-block" [routerLink]="'contacto'">
        <div class="txt">Contacto</div>
    </div>
    <!--</div>-->
    <a class="area-cliente" href="http://clientes.nextfibra.com/nextfibra/" target="_blank">
        Área Cliente <i class="far fa-user"></i>
    </a>

</nav>


