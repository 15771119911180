import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AppSettingsService} from '../library/appSettings.service';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-contacto',
    templateUrl: './contacto.component.html',
    styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {

    contacto: FormGroup;
    correoEnviadoStatus = 0; // 0: SIN ENVIAR - 1: SUCCES OK - 2: ERROR

    constructor(public appSettings: AppSettingsService,
                private http: HttpClient) {
        this.contacto = new FormGroup({
            nombre: new FormControl(),
            correo: new FormControl(),
            telefono: new FormControl(),
            texto: new FormControl(),
        });

    }

    ngOnInit(): void {
    }

    enviar(): void {
        const formData = new FormData();
        formData.append('name', this.contacto.get('nombre').value);
        formData.append('email', this.contacto.get('correo').value);
        formData.append('phone', this.contacto.get('telefono').value);
        formData.append('subject', this.contacto.get('texto').value);

        this.http.post('/assets/php/contact-form.php', formData).subscribe(data => {
                this.correoEnviadoStatus = 1;
                console.log(data);
            },
            (err) => {
                this.correoEnviadoStatus = 2;
                console.error(err);
            });
    }

}

export interface DatosContacto {
    nombre?: string;
    correo?: string;
    telefono?: string;
    texto?: string;
}
