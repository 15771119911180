import {Component, Input, OnInit} from '@angular/core';
import {Producto} from '../models/Producto';
import {ProductoCompartido} from '../models/ProductoCompartido';

@Component({
  selector: 'app-price-compartido',
  templateUrl: './price-compartido.component.html',
  styleUrls: ['./price-compartido.component.scss']
})
export class PriceCompartidoComponent implements OnInit {

  @Input() tarifa: ProductoCompartido;
  @Input() animation = false;
  @Input() features = false;

  constructor() {
  }

  ngOnInit(): void {
  }

}
