import {Component, OnInit} from '@angular/core';
import {Producto} from '../library/models/Producto';
import {ActivatedRoute} from '@angular/router';
import {ProductService} from '../library/product.service';
import {BackdropService} from '../library/backdrop.service';
import {ProductoCompartido} from '../library/models/ProductoCompartido';

@Component({
    selector: 'app-tarifas-compartidas',
    templateUrl: './tarifas-compartidas.component.html',
    styleUrls: ['./tarifas-compartidas.component.scss']
})
export class TarifasCompartidasComponent implements OnInit {


    tarifasToShow: Array<ProductoCompartido> = [];

    constructor(private productService: ProductService,
                private backdropService: BackdropService) {

        this.tarifasToShow = this.productService.tarifasCompartidas;
    }


    ngOnInit(): void {
        this.backdropService.closeBackdrop();
    }


}
