import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {LandingComponent} from './landing.component';
import {CarouselComponent} from './carousel/carousel.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { PricingComponent } from './pricing/pricing.component';
import {LibraryModule} from '../library/library.module';
import { FeaturesComponent } from './features/features.component';
import { MarketingComponent } from './marketing/marketing.component';
import { CifrasComponent } from './cifras/cifras.component';
import { MobileAppComponent } from './mobile-app/mobile-app.component';
import {DudasComponent} from './dudas/dudas.component';
import {RouterModule} from '@angular/router';
import { CoberturaComponent } from './marketing/cobertura/cobertura.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule} from '@angular/forms';


@NgModule({
    declarations: [
        LandingComponent,
        CarouselComponent,
        PricingComponent,
        FeaturesComponent,
        MarketingComponent,
        CifrasComponent,
        MobileAppComponent,
        DudasComponent,
        CoberturaComponent],
    exports: [],
    imports: [
        BrowserModule,
        LibraryModule,
        NgbModule,
        RouterModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatButtonModule,
        FormsModule,
    ],
})
export class LandingModule {
}
