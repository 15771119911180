<div class="page-padding">

    <div class="header">
        <app-title-deco></app-title-deco>
        <div class="title">Nextfibra: ahorra con total confianza</div>
    </div>

    <div class="row features">
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <figure> <i class="fas fa-check-circle"></i></figure>
            <div class="description">Nuestros precios no son promocionales, sino <strong>definitivos</strong>, no se incrementarán después.</div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <figure> <i class="fas fa-check-circle"></i></figure>
            <div class="description">Nuestras tarifas móviles no tienen <strong>permanencia</strong>: ven y vete cuando desees</div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <figure> <i class="fas fa-check-circle"></i></figure>
            <div class="description"><strong>Acumula</strong> los GB que no gastes para el mes siguiente, o <strong>compártelos</strong> con otros clientes de Nextfibra</div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <figure> <i class="fas fa-check-circle"></i></figure>
            <div class="description">Nuestra Fibra no lleva línea telefónica, por lo que te <strong>ahorras la cuota de línea.</strong></div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <figure> <i class="fas fa-check-circle"></i></figure>
            <div class="description">Utilizamos la red 4G+ de Vodafone y Movistar: según estudios independientes, <strong>las mejores de España.</strong></div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <figure> <i class="fas fa-check-circle"></i></figure>
            <div class="description"> <strong>Atendemos con personas</strong>, y aplicamos las ofertas siempre a nuestros clientes primero.</div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <figure> <i class="fas fa-check-circle"></i></figure>
            <div class="description">Proporcionamos una  <strong>aplicación intuitiva y fácil de usar</strong> para gestionar tus servicios.</div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <figure> <i class="fas fa-check-circle"></i></figure>
            <div class="description"> <strong>Más de 2.000 clientes</strong> ya confían en Nextfibra en tan sólo 7 años.</div>
        </div>
    </div>

</div>
