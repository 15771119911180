import {Component, OnInit} from '@angular/core';
import {ProductService} from '../../library/product.service';

@Component({
    selector: 'app-landing-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss', './../lading-pages.scss']
})
export class PricingComponent implements OnInit {

    tarifas = this.productService.tarifas.filter(x => x.destacado);


    constructor(private productService: ProductService) {
    }

    ngOnInit(): void {
        this.tarifas.push({
            id: -1,
            precioEUR: 29,
            precioCENT: 80,
            isFibra: true,
            isMovil: true,
            nombre: 'Fibra 600 MB + 5Gb Movil',
            descripcion: ' 600MB de Fibra y 5Gb de datos para tu movil',
            destacado: true,
            fibraMb: 600,
            telGb: 5
        });
    }


}
