import {Component, Input, OnInit} from '@angular/core';
import {Producto} from '../models/Producto';

@Component({
    selector: 'app-price',
    templateUrl: './price.component.html',
    styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit {

    @Input() tarifa: Producto;
    @Input() animation = false;
    @Input() features = false;

    constructor() {
    }

    ngOnInit(): void {
    }

}


