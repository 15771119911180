import {Component, OnInit} from '@angular/core';
import {BackdropService} from '../backdrop.service';
import {AppSettingsService} from '../appSettings.service';
import {ProductService} from '../product.service';
import {Producto} from '../models/Producto';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    public tarifasMovil: Producto[];
    public tarifasFibra: Producto[];
    public tarifasFibraYMovil: Producto[];

    constructor(public backdropService: BackdropService,
                public appSettings: AppSettingsService,
                private productService: ProductService) {

        this.tarifasMovil = this.productService.tarifas.filter(x => x.isMovil && !x.isFibra);
        this.tarifasFibra = this.productService.tarifas.filter(x => !x.isMovil && x.isFibra);
        this.tarifasFibraYMovil = this.productService.tarifas.filter(x => x.isMovil && x.isFibra);
    }

    ngOnInit(): void {
    }

}
