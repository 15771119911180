import {Component} from '@angular/core';
import {BackdropService} from './library/backdrop.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    title = 'nextfibraweb';

    constructor(public backdropService: BackdropService) {
    }
}
