<section class="carousel container-fluid">
    <app-landing-carousel></app-landing-carousel>
</section>

<section class="pricing container-fluid">
    <app-landing-pricing></app-landing-pricing>
</section>

<section class="features container-fluid">
    <app-landing-features></app-landing-features>
</section>

<section class="marketing no-bottom-padding no-top-padding container-fluid">
    <app-landing-marketing></app-landing-marketing>
</section>

<section class="cifras container-fluid">
    <app-landing-cifras></app-landing-cifras>
</section>

<section class="mobile-app container-fluid">
    <app-landing-mobile-app></app-landing-mobile-app>
</section>

<section class="dudas container-fluid">
    <app-landing-dudas></app-landing-dudas>
</section>
