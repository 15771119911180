<div class="page-padding">


    <div class="header">
        <app-title-deco></app-title-deco>
        <div class="title">Las cifras que nos avalan</div>
    </div>

    <div class="row cifras">
        <div class="col-12 col-md-3 cifra">
            <div class="small"> MÁS DE </div>
            <div class="big"> 3.150</div>
            <div class="normal"> clientes satisfechos</div>
        </div>
        <div class="col-12 col-md-3 cifra">
            <div class="small"> HEMOS CABLEADO</div>
            <div class="big"> 4 </div>
            <div class="normal"> poblaciones </div>
        </div>
        <div class="col-12 col-md-3 cifra">
            <div class="small"> TENEMOS</div>
            <div class="big"> 12 </div>
            <div class="normal"> años de experiencia</div>
        </div>
        <div class="col-12 col-md-3 cifra">
            <div class="small"> SOMOS</div>
            <div class="big"> 24 </div>
            <div class="normal"> profesionales a tu servicio</div>
        </div>
    </div>

</div>
