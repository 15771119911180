<div class="contact-page page-padding">

    <div class="title">Contacto</div>
    <app-title-deco></app-title-deco>

    <div class="row">

        <div class="col-12 col-md-6 contacto">

            <h3>Contacta con nosotros </h3>
            <p>Rellena este formulario y te ayudaremos con tus dudas </p>


            <form [formGroup]="contacto" *ngIf="correoEnviadoStatus === 0">
                <mat-form-field appearance="outline">
                    <mat-label>Nombre</mat-label>
                    <input matInput placeholder="Maite" formControlName="nombre" required type="text">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Correo electrónico</mat-label>
                    <input matInput placeholder="maitegarcia@gmail.com" formControlName="correo" required
                           type="email">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Número de teléfono</mat-label>
                    <input matInput placeholder="613478965" formControlName="telefono" required type="tel">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>En que podemos ayudarte</mat-label>
                    <textarea matInput rows="5" formControlName="texto" required type="text"></textarea>
                </mat-form-field>

                <button mat-raised-button class="enviar" [disabled]="contacto.invalid" (click)="enviar()">Enviar
                </button>
            </form>

            <div *ngIf="correoEnviadoStatus === 1" class="send success">
                <div class="icon">
                    <i class="fas fa-check-circle"></i>
                </div>
                <div class="text">
                    El mensaje ha sido enviar correctamente
                </div>
            </div>

            <div *ngIf="correoEnviadoStatus === 2" class="send error">
                <div class="icon">
                    <i class="fas fa-times"></i>
                </div>
                <div class="text">
                   Ha habido algún problema inesperado. Inténtelo mas tarde o contacte con el administrador.
                </div>
            </div>
        </div>

        <div class="col-12 col-md-6 info">
            <div class="black-box">

                <h2>Teléfono Nextfibra: {{appSettings.tel}}</h2>
                <p>Nuestro horario de oficina de 09:00 a 15:00<br/>
                    Servicio técnico de 09:00 a 22:00</p>

                <h2 class="second">¿Robots? No, gracias.</h2>
                <p>Todavía quedan unos años para tener una relación seria con un robot. Por ahora, nuestro trato será de
                    tú a tú.</p>
                <p>¡Llámanos!</p>
                <div class="redes">
                    <p>También puedes contactarnos por redes sociales</p>
                    <div class="social-media d-flex">
                        <div class="media" *ngIf="appSettings.social.facebook">
                            <a [href]="appSettings.social.facebook" target="_blank">
                                <i class="fab fa-facebook-square"></i>
                            </a>
                        </div>
                        <div class="media" *ngIf="appSettings.social.twitter">
                            <a [href]="appSettings.social.twitter" target="_blank">
                                <i class="fab fa-twitter-square"></i>
                            </a>
                        </div>
                        <div class="media" *ngIf="appSettings.social.youtube">
                            <a [href]="appSettings.social.youtube" target="_blank">
                                <i class="fab fa-youtube-square"></i></a>
                        </div>
                        <div class="media" *ngIf="appSettings.social.instagram">
                            <a [href]="appSettings.social.instagram" target="_blank">
                                <i class="fab fa-instagram-square"></i>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
