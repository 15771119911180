<div class="tarifa" [class.animated]="animation">

    <div class="pre-titulo">Llamadas ILIMITADAS</div>
    <div class="titulo">{{tarifa.nombre}}</div>

    <div class="price">
        <span class="eur">{{tarifa.precioEUR}}</span>
        <span class="eur">’</span>
        <span class="eur">{{tarifa.precioCENT}}</span>
        <span class="mes">/ mes (iva inc.)</span>

    </div>

    <app-title-deco></app-title-deco>

    <div class="descripcion" >
        {{tarifa.compartido.length}} LINEAS MOVILES A COMPARTIR
    </div>

    <div class="caracteristicas">
       <div class="caracteristica" *ngFor="let tar of tarifa.compartido">
           <span class="fas fa-mobile-alt"></span> {{tar}} GB
       </div>
    </div>

    <div class="getIt">
        <button type="button" class="btn" [routerLink]="'/contacto'">¡Lo Quiero!</button>
    </div>


</div>
