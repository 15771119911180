import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-dudas',
  templateUrl: './dudas.component.html',
  styleUrls: ['./dudas.component.scss', './../lading-pages.scss']
})
export class DudasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
