import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {NavbarComponent} from './navbar/navbar.component';
import { PriceComponent } from './price/price.component';
import { TitleDecoComponent } from './title-deco/title-deco.component';
import {RouterModule} from '@angular/router';
import { PriceCompartidoComponent } from './price-compartido/price-compartido.component';

@NgModule({
  declarations: [
    NavbarComponent,
    PriceComponent,
    TitleDecoComponent,
    PriceCompartidoComponent
  ],
    exports: [
        NavbarComponent,
        PriceComponent,
        TitleDecoComponent,
        PriceCompartidoComponent
    ],
    imports: [
        BrowserModule,
        RouterModule,
    ],
})
export class LibraryModule {
}
