import {Component, OnInit} from '@angular/core';

@Component({
    templateUrl: './cobertura.component.html',
    styleUrls: ['./cobertura.component.scss']
})
export class CoberturaComponent implements OnInit {

    state = 1;
    direccion: string;
    numero: string;

    constructor() {
    }

    ngOnInit(): void {
    }

    checkCobertura(): void {
        this.state = 2;

        setTimeout(() => {
            this.state = 3;
        }, 2000);
    }
}
