<div class="secondary-footer ">
    <div class="container-fluid page-padding">
        <div class="row">
            <div class="col-md-6 col-xs-12">
                <div class="big">¡Hey, 👋🏻 llámanos al {{appSettings.tel}}</div>
                <div class="small">Nuestro horario de oficina de 09:00 a 15:00</div>
                <div class="small">Servicio técnico de 09:00 a 22:00</div>
            </div>

            <div class="col-md-6 col-xs-12">
                <div class="big">...o si lo prefieres Te llamamos nosotros 🤙🏻!</div>
                <div class="small">
                    <a [routerLink]="'/contacto'"> Danos tus datos y te llamamos ahora + </a></div>
            </div>
        </div>
    </div>


    <div class="primary-footer ">
        <div class="container-fluid page-padding">

            <div class="d-flex justify-content-between main-data ">
                <div class="enterprise">
                    Nextfibra
                </div>
                <div class="social-media d-flex">
                    <div class="media" *ngIf="appSettings.social.facebook">
                        <a [href]="appSettings.social.facebook" target="_blank">
                            <i class="fab fa-facebook-square"></i>
                        </a>
                    </div>
                    <div class="media" *ngIf="appSettings.social.twitter">
                        <a [href]="appSettings.social.twitter" target="_blank">
                            <i class="fab fa-twitter-square"></i>
                        </a>
                    </div>
                    <div class="media" *ngIf="appSettings.social.youtube">
                        <a [href]="appSettings.social.youtube" target="_blank">
                            <i class="fab fa-youtube-square"></i>
                        </a>
                    </div>
                    <div class="media" *ngIf="appSettings.social.instagram">
                        <a [href]="appSettings.social.instagram" target="_blank">
                            <i class="fab fa-instagram-square"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <div class="contact row">
                    <div class="col-md-4 col-xs-12">
                        <h3>Atención al cliente</h3>

                        <ul class="list-unstyled">
                            <li><a [routerLink]="'/contacto'">Página de contacto</a></li>
                            <li><a [href]="'tel:' + appSettings.tel">Llámenos</a></li>
                        </ul>
                    </div>

                    <div class="col-md-4 col-xs-12">
                     <!--   <h3>Sobre la empresa</h3>

                        <ul class="list-unstyled">
                            <li><a href="#">Sobre nosotros</a></li>
                            <li><a href="#">Centro de ayuda</a></li>
                        </ul>-->
                    </div>

                    <div class="col-md-4 col-xs-12">
                        <h3>Dónde estamos</h3>

                        <ul class="list-unstyled">
                            <li><strong>Umbrete</strong>: Calle Ruperto Escobar, 33</li>
                            <li><strong>Olivares</strong>: Avenida Juan Pablo II, local </li>
                        </ul>
                    </div>
                </div>

                <div class="app  d-none d-xl-block d-lg-block d-md-block">
                    <p>Descarga nuestra app: </p>
                    <div class="d-flex download">
                        <a [href]="appSettings.appAndroid" target="_blank">
                            <img src="assets/img/designs/gplaystore.svg" alt=""/>
                        </a>
                        <a [href]="appSettings.appApple" target="_blank">
                            <img src="assets/img/designs/appstore.svg" alt=""/>
                        </a>
                    </div>
                </div>


            </div>
        </div>
    </div>

</div>
